<template>
  <section
    id="about"
    class="overflow-hidden"
  >
    <v-row no-gutters>
      <v-col
        class="pa-5"
        cols="12"
      >
        <base-subheading class="info--text text-center">
          サイト運営会社情報
        </base-subheading>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        class="fill-height"
        cols="12"
        md="4"
      >
        <v-card
          outlined
          tile
          class="mb-0 mb-sm-8 mb-md-16"
        >
          <v-card-title class="pa-2">
            <h2 class="info--text mx-auto font-weight-light text-h6 text-sm-h5">
              日本
            </h2>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <address>
              Throne Inc.<br>
              株式会社スローネ<br>
              <v-icon>mdi-office-building</v-icon>〒150-0047 東京都渋谷区神山町2-9　フォーラム神山2F<br>
              <!--<v-icon>mdi-phone</v-icon><a href="tel:+81-03-3469-8456">03-3469-8456</a><br>-->
              <!--<v-icon>mdi-fax</v-icon><a href="fax:+81-03-5549-4690">03-5549-4690</a><br>-->
              <!--<v-icon>mdi-email</v-icon><a href="mailto:helee@throne.asia">helee@throne.asia</a>-->
            </address>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        class="fill-height"
        cols="12"
        md="4"
        align-self="end"
      >
        <v-card outlined>
          <v-card-title class="pa-2">
            <h2 class="info--text mx-auto font-weight-light text-h6 text-sm-h5">
              TAICCA
            </h2>
          </v-card-title>
          <v-divider />
          <v-card-text class="text-center">
            <v-btn
              color="primary"
              outlined
              small
              target="_blank"
              rel="noopener"
              href="https://taicca.tw"
            >
              Taiwan Creative Content Agency (TAICCA)
            </v-btn>
            <address class="text-left">
              <v-icon>mdi-office-building</v-icon>North Tobacco Factory 2F., No.133, Guangfu S. Rd., Xinyi Dist, Taipei City 11072, Taiwan<br>
              <v-icon>mdi-office-building</v-icon>11072 台北市信義區光復南路133號 松山文創園區 北向製菸工廠2樓
            </address>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        class="fill-height"
        cols="12"
        md="4"
      >
        <v-card
          outlined
          class="mb-0 mb-sm-8 mb-md-16"
        >
          <v-card-title class="pa-2">
            <h2 class="info--text mx-auto font-weight-light text-h6 text-sm-h5">
              台湾
            </h2>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <address>
              NADA 綺泰<br>
              <v-icon>mdi-office-building</v-icon>10F., No. 191, Fuxing N. Rd., Songshan Dist., Taipei City 105, Taiwan<br>
              <v-icon>mdi-office-building</v-icon>105台北市松山區復興北路191號10樓
              <!--<v-icon>mdi-phone</v-icon> <a href="tel:+886 (02) 2321-1600">+886 (02) 2321-1600</a><br>
              <v-icon>mdi-fax</v-icon> <a href="fax:+886 (02) 2321-0669">+886 (02) 2321-0669</a>-->
            </address>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'AboutUs'
  }
</script>
